// extracted by mini-css-extract-plugin
export const formSection = "Form-module--formSection--UWbKA";
export const fakeAnchor = "Form-module--fakeAnchor--3AONu";
export const inputContainer = "Form-module--inputContainer--37kG_";
export const containerClass = "Form-module--containerClass--1Ple2";
export const input = "Form-module--input--1Nrh5";
export const label = "Form-module--label--F2zTR";
export const helperText = "Form-module--helperText--1CWPH";
export const submitButton = "Form-module--submitButton--2zdwA";
export const formDisabled = "Form-module--formDisabled--3OoNy";
export const formReady = "Form-module--formReady--2yEVq";
export const formSubmitting = "Form-module--formSubmitting--2qtcJ";
export const formSubmitted = "Form-module--formSubmitted--27nUz";
export const formMessage = "Form-module--formMessage--1PsrB";
export const formMessageSuccess = "Form-module--formMessageSuccess--3Go7c";
export const formMessageError = "Form-module--formMessageError--1OktL";
export const bottomNote = "Form-module--bottomNote--HUMH6";