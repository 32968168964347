import React from 'react';
import { Parallax } from 'react-parallax';
import AnchorLink from 'react-anchor-link-smooth-scroll';

import logo from '../../images/logo.svg';
import background from '../../images/webinarBg.jpg';
import * as styles from './Hero.module.scss';

const Hero = (): React.ReactElement => {
  return (
    <section className={styles.heroSection}>
      <Parallax
        className={styles.parallaxContainer}
        bgClassName={styles.background}
        contentClassName={styles.container}
        renderLayer={() => (
          <div
            className={styles.overlay}
            style={{
              background: `rgba(0, 0, 0, 0.2)`,
            }}
          ></div>
        )}
        // blur={2}
        bgImage={background}
        bgImageAlt=""
        strength={200}
      >
        <div className={styles.logoContainer}>
          <img src={logo} alt="Pela Terra" />
        </div>
        <div className={styles.content}>
          <h1>Explaining the Golden Visa Program in Portugal</h1>
          <p>
            You may have heard of the Golden Visa Program in Portugal, or you might be looking for
            great investment opportunities, and you want to understand if it is the right option for
            you, then this webinar is for you.
          </p>
          <div className={styles.dateAndButton}>
            <div className={styles.dateContainer}>
              <span className={styles.date}>June 15th</span>
              <span className={styles.time}>14:00 GMT+1 | Duration 30min</span>
            </div>
            <AnchorLink href="#anchor">
              <button className={styles.button}>Register now</button>
            </AnchorLink>
          </div>
        </div>
      </Parallax>
    </section>
  );
};

export default Hero;
