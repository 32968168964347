import React, { useEffect, useRef } from 'react';
import * as Sentry from '@sentry/react';
import { PHONE_COUNTRY_CODE_BY_COUNTRY } from '../../constants';

import { useFormField, useForm } from '../../utils/forms';
import InputField from '../InputField';
import * as styles from './Form.module.scss';

const Form = (): React.ReactElement => {
  const country = useRef<string | null>(null);
  const phoneCountryCode = country.current
    ? PHONE_COUNTRY_CODE_BY_COUNTRY[country.current] || ''
    : '';

  const fieldsByName = {
    name: useFormField<string>('', ['required']),
    email: useFormField<string>('', ['required', 'email']),
    phone: useFormField<string>('', [
      'required',
      phone => (phone === phoneCountryCode ? 'This field is required.' : null),
    ]),
  } as const;

  useEffect(() => {
    try {
      const queryParams = new URLSearchParams(window.location.search);
      country.current = queryParams.get('country') || null;
      fieldsByName.name.setValue(queryParams.get('name') || '');
      fieldsByName.email.setValue(queryParams.get('email') || '');
      let phone = queryParams.get('phone') || '';
      if (phone[0] === ' ') {
        phone = '+' + phone.slice(1);
      }
      if (!phone && country.current) {
        if (PHONE_COUNTRY_CODE_BY_COUNTRY[country.current] === undefined) {
          Sentry.captureException(
            `Missing country ${country.current} on PHONE_COUNTRY_CODE_BY_COUNTRY`,
          );
        }
        phone = PHONE_COUNTRY_CODE_BY_COUNTRY[country.current];
      }
      fieldsByName.phone.setValue(phone);
    } catch (error) {
      Sentry.captureException(error);
    }
  }, []);

  async function submitToHubspot() {
    // await wait(2000); // Case loading
    // throw new Error('Got response with status code 400'); // Case unknown error
    // throw new Error('Failed to fetch'); // Case network error
    // return true; // Case success
    const hubspotData = {
      name: fieldsByName.name.value,
      email: fieldsByName.email.value,
      phone: fieldsByName.phone.value,
      country: country.current,
      webinar: true,
    };
    console.log('Submitting hubspotData:', hubspotData);
    if (fieldsByName.name.value === '_TEST_SENTRY_') {
      throw new Error('Test sentry error');
    }
    const resp = await fetch('/.netlify/functions/submit-to-hubspot', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(hubspotData),
    });
    if (resp.status !== 200) {
      if (resp.status === 400) {
        const data = await resp.json();
        if (data.error === 'INVALID_EMAIL') {
          fieldsByName.email.setError('Invalid email format');
          return false;
        }
      } else {
        throw new Error('Got response with status code ' + resp.status);
      }
    }

    // if (form2FieldsByName.terms1.value && 'dataLayer' in window) {
    //   window.dataLayer.push({ event: 'QualifiedLeadSubmit' });
    // }
    return true;
  }

  const { getFieldProps, renderSubmitButton, renderFormMessage } = useForm({
    fieldsByName,
    onSubmit: submitToHubspot,
    translateFunction: key => {
      return {
        'form.required_field_error': 'This field is required.',
        'form.invalid_email_error': 'Invalid email format',

        'form.network_error':
          'Network failed to send your request. Please, check your connection and try again.',

        'form.unknown_error': 'An unknown error has occurred. Please, try again.',

        'form.success_message': '',
      }[key];
    },
  });

  return (
    <section className={styles.formSection}>
      <span className={styles.fakeAnchor} id="anchor"></span>
      <h2>Join us</h2>
      <p>
        With this webinar, we aim to eliminate any doubts you may have and bring you one step closer
        to a profitable and low-risk investment. The only action you need to take today is to
        register for free.
      </p>

      <form name="contact-form" data-netlify="true">
        <div className={styles.inputContainer}>
          <span className={styles.label}>Name</span>
          <InputField
            containerClass={styles.containerClass}
            className={styles.input}
            type="text"
            id="name-input"
            name="name"
            helperTextClass={styles.helperText}
            {...getFieldProps(fieldsByName.name)}
          />
        </div>
        <div className={styles.inputContainer}>
          <span className={styles.label}>Email</span>
          <InputField
            containerClass={styles.containerClass}
            className={styles.input}
            type="email"
            id="email-input"
            name="email"
            helperTextClass={styles.helperText}
            {...getFieldProps(fieldsByName.email)}
          />
        </div>
        <div className={styles.inputContainer}>
          <span className={styles.label}>Phone Number</span>
          <InputField
            containerClass={styles.containerClass}
            className={styles.input}
            type="phone"
            id="phone-input"
            name="phone"
            helperTextClass={styles.helperText}
            {...getFieldProps(fieldsByName.phone)}
          />
        </div>
        {renderSubmitButton({
          labels: {
            disabled: 'Register',
            ready: 'Register',
            submitting: 'Registering',
            submitted: 'Thank you for registering.',
          },
          btnClasses: {
            common: styles.submitButton,
            disabled: styles.formDisabled,
            ready: styles.formReady,
            submitting: styles.formSubmitting,
            submitted: styles.formSubmitted,
          },
          iconClasses: {
            common: 'fas',
            disabled: '',
            ready: '',
            submitting: 'fa-spinner fa-spin',
            submitted: 'fa-check',
          },
        })}
        {renderFormMessage({
          styles: {
            formMessage: styles.formMessage,
            formMessageSuccess: styles.formMessageSuccess,
            formMessageError: styles.formMessageError,
          },
        })}
      </form>
      <span className={styles.bottomNote}>
        By completing this form you are opting into emails from Pela Terra. You can unsubscribe at
        any time.
      </span>
    </section>
  );
};

export default Form;
