// extracted by mini-css-extract-plugin
export const heroSection = "Hero-module--heroSection--lS8-h";
export const parallaxContainer = "Hero-module--parallaxContainer--1NfYD";
export const container = "Hero-module--container--rXPbv";
export const overlay = "Hero-module--overlay--1Ch_J";
export const logoContainer = "Hero-module--logoContainer--12uMI";
export const content = "Hero-module--content--3pocf";
export const dateAndButton = "Hero-module--dateAndButton--uu_iV";
export const dateContainer = "Hero-module--dateContainer--28zvL";
export const date = "Hero-module--date--2uhDZ";
export const time = "Hero-module--time--ewsKc";
export const button = "Hero-module--button--XY03l";