import React from 'react';
import * as styles from './Footer.module.scss';
import logo from '../../images/logo.svg';

const Footer = (): React.ReactElement => {
  return (
    <footer className={styles.footer}>
      <div className={styles.footerContainer}>
        <div className={styles.leftContainer}>
          <div className={styles.logoContainer}>
            <img src={logo} alt="Pela Terra" />
          </div>
        </div>
        <div className={styles.rightContainer}>
          <span className={styles.span}>
            {new Date().getFullYear()} © All rights reserved to Pela Terra
          </span>
          <span className={styles.span}>
            Powered by{' '}
            <a
              className={styles.moscaLink}
              target="_blank"
              rel="noreferrer"
              href={'https://moscadigital.pt/en/'}
            >
              Mosca Digital
            </a>
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
