import React from 'react';
import '../styles/typography.scss';

import Form from '../components/webinar/Form';
import About from '../components/webinar/About';
import Campaign from '../components/webinar/Campaign';
import Hero from '../components/webinar/Hero';
import Footer from '../components/webinar/Footer';

const Webinar = (): React.ReactElement => {
  return (
    <>
      <Hero></Hero>
      <About></About>
      <Campaign></Campaign>
      <Form></Form>
      <Footer></Footer>
    </>
  );
};

export default Webinar;
