import React from 'react';
import * as styles from './About.module.scss';
import image from '../../images/webinar.jpg';

const About = (): React.ReactElement => {
  return (
    <section className={styles.aboutSection}>
      <div className={styles.imageContainer}>
        {/* <GatsbyImage
            alt=""
            className={styles.image}
            image={data.topicsGatsbyImage.childImageSharp.fluid}
          ></GatsbyImage> */}
        <img src={image} alt="" />
      </div>
      <div className={styles.content}>
        <h2>What you will learn</h2>
        <ul>
          <li>What are the main benefits of the program?</li>
          <li>Why was it created?</li>
          <li>Why is Portugal an exciting place to have citizenship?</li>
          <li>Is it a good option for someone looking for a work/self-employed visa or not?</li>
          <li>What are the main differences between Real Estate and Fund?</li>
          <li>
            How does the Pela Terra Investment Fund work - compelling asset class, simple investment
            thesis, annual dividends, decent returns, triple bottom line & more?
          </li>
          <li>What is the process one needs to follow to get accepted?</li>
        </ul>
      </div>
    </section>
  );
};
export default About;
