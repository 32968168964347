import React from 'react';
import { Background, Parallax } from 'react-parallax';
import { GatsbyImage } from 'gatsby-plugin-image';
import campaignImage from '../../images/campaign.jpg';

import * as styles from './Campaign.module.scss';

const Campaign = (): React.ReactElement => {
  return (
    <section className={styles.campaignSection}>
      <Parallax
        className={styles.parallaxContainer}
        contentClassName={styles.contentContainer}
        strength={400}
      >
        <Background className={styles.background}>
          <img src={campaignImage} alt="" className={styles.image} />
          {/* <GatsbyImage
              alt=""
              className={styles.image}
              image={themeData.image.asset.gatsbyImageData}
            ></GatsbyImage> */}
        </Background>
      </Parallax>
      <span className={styles.copy}>
        You can’t buy the world, but your kids will surely own it.
      </span>
    </section>
  );
};

export default Campaign;
